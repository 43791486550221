<script lang="ts" setup>
defineProps<{ clearable?: boolean }>()
defineEmits<{ clear: [] }>()

const slots: Record<string, any> = useSlots()
</script>

<!-- Wrapper component for NuxtUI SelectMenu since the label count is broken -->
<template>
  <USelectMenu v-bind="$attrs">
    <template v-for="(_, name) in slots" #[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>
    <template v-if="clearable" #trailing>
      <UButton
        icon="light:xmark"
        variant="ghost"
        class="pointer-events-auto text-gray-400 hover:bg-transparent hover:text-gray-900 dark:text-gray-500 dark:hover:text-gray-100"
        @click.stop="$emit('clear')"
      />
      <UIcon
        name="light:chevron-down" class="size-5 shrink-0 text-gray-400 dark:text-gray-500"
      />
    </template>
    <template v-if="$attrs.multiple && Array.isArray($attrs.modelValue)" #label>
      {{ $attrs.modelValue.length }} selected
    </template>
  </USelectMenu>
</template>
